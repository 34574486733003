/** @jsx jsx */
import { jsx } from 'theme-ui';

import { H3, P } from '../../components/Text';
import { AboutLayout } from '../../layouts/AboutLayout';

const AboutPrinciplesPage = () => {
  return (
    <AboutLayout>
      <H3>Our Principles</H3>
      <P>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id
        gravida elit. Cras sed dolor arcu. Integer sed tellus quis sapien
        laoreet aliquet non suscipit enim. In non rutrum ipsum. Nunc sit amet
        lorem interdum erat fermentum eleifend. Fusce tortor nisl, consectetur
        in quam at, rhoncus ultrices lectus. Ut id nulla magna. Nulla laoreet
        luctus mauris. Maecenas luctus elementum laoreet. Donec id mi mauris.
        Donec condimentum libero quis nulla venenatis, et ornare enim sodales.
        Nullam commodo libero vitae sapien lobortis, eget cursus ex posuere.
        Donec accumsan, leo id ultrices sodales, eros nisl malesuada erat, ut
        condimentum justo urna sed ligula. Mauris non nisl et sem posuere
        dapibus. Curabitur ipsum nulla, egestas in orci et, dapibus dapibus ex.
      </P>
      <P>
        Quisque eget nisl tempus, dictum massa quis, semper arcu. Curabitur
        posuere eu magna non accumsan. Phasellus feugiat consectetur lacus.
        Integer feugiat lacus nulla, nec euismod metus iaculis sit amet. Aliquam
        non convallis purus. Proin iaculis non justo ut euismod. Duis eros sem,
        cursus ac mi id, malesuada pretium metus.
      </P>
    </AboutLayout>
  );
};

export default AboutPrinciplesPage;
